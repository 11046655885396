<template>
  <v-card :height="210">
    <v-card-title class="mb-0 pb-1">
      Payment Details
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            v-for="detail in paymentDetails"
            :key="detail.label"
            cols="12"
            md="3"
            class="mb-4"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                {{ detail.label }}
              </h4>
              <h4 class="font-weight-medium">
                <span v-if="detail.label === 'Amount Paid'">Ksh {{ detail.value | formatCurrency }}</span>
                <span v-else-if="detail.label === 'Payment Date'">{{ detail.value | formatDate }}</span>
                <span v-else>{{ detail.value }}</span>
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    payment: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    paymentDetails() {
      if (!this.payment) return []

      const mpesaTransaction = this.payment.mpesa_transaction
      const { customer } = this.payment

      return [
        { label: 'Payment Reference', value: this.payment.reference || 'N/A' },
        { label: 'Amount Paid', value: this.payment.amount || 'N/A' },
        { label: 'Name', value: mpesaTransaction ? (`${mpesaTransaction.first_name} ${mpesaTransaction.last_name}`) : customer.user.name || 'N/A' },
        { label: 'Phone Number', value: mpesaTransaction ? mpesaTransaction.msisdn : customer.user.phone_number || 'N/A' },
        { label: 'Payment Date', value: this.payment.created_at || 'N/A' },
        { label: 'Paid To', value: this.payment.paid_to ? `${this.payment.paid_to.name} (${this.payment.paid_to.email})` : 'N/A' },
      ]
    },
  },
}
</script>
