<template>
  <div class="payments">
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="hidden-sm-and-down"
      >
        <v-btn
          class="go-back-button"
          @click="$router.go(-1)"
        >
          <v-icon class="mr-1">
            {{ icons.mdiArrowLeft }}
          </v-icon>
          Go back
        </v-btn>
      </v-col>
    </v-row>
    <payment-details-card
      :payment="details"
      :is-loading="isLoading"
    />
    <br><invoices-table
      :invoices-url="'invoices'"
      :payment-id="paymentId"
    />
    <br><br>
    <customer-details-card
      :customer="details.customer"
      :is-loading="isLoading"
    />
  </div>
</template>
<script>
import axios from 'axios'
import {
  mdiArrowLeft,
} from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import CustomerDetailsCard from '@/components/details-card/CustomerDetailsCard.vue'
import PaymentDetailsCard from '@/components/details-card/PaymentDetailsCard.vue'
import InvoiceDetailsCard from '@/components/details-card/InvoiceDetailsCard.vue'
import InvoicesTable from '@/components/tables/InvoicesTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    'customer-details-card': CustomerDetailsCard,
    'payment-details-card': PaymentDetailsCard,
    'invoice-details-card': InvoiceDetailsCard,
    InvoicesTable,
  },
  data() {
    return {
      search: '',
      details: { customer: { user: { name: '' } }, invoice: {}, payment: {} },
      isLoading: true,
      paymentId: this.$route.params.id,
      icons: {
        mdiArrowLeft,
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Payments',
          disabled: false,
          to: { name: 'payments' },
        },
      ],
    }
  },
  mounted() {
    this.getPayment()
    if (this.paymentId) {
      this.breadcrumbs.push(
        {
          text: this.paymentId,
          disabled: true,
          to: { name: 'payment-details' },
        },
      )
    }
  },
  methods: {
    getPayment() {
      this.isLoading = true
      axios
        .get(`payments/${this.paymentId}`)
        .then(response => {
          this.details = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
